import '@fontsource/dm-sans';
import '@fontsource/poppins';
import '@fontsource/roboto';
import React from 'react';
import { RecoilRoot } from 'recoil';

import { ErrorPage } from 'pages/error-page';
import { ErrorCardContainer } from 'pages/error-page/ErrorCardContainer';

import { Auth0Provider } from 'providers/Auth0Provider';
import { AxiosProvider } from 'providers/AxiosProvider';
import { BuildVersionProvider } from 'providers/BuildVersionProvider';
import { LdProvider } from 'providers/LdProvider';
import { PermissionsProvider } from 'providers/PermissionsProvider';
import { ReactQueryProvider } from 'providers/ReactQueryProvider';
import { SnackBarProvider } from 'providers/SnackBarProvider';
import { TrackingProvider } from 'providers/TrackingProvider';

import { AppRouter } from './AppRouter';
import { GlobalErrorBoundary } from './error-boundaries/GlobaErrorBoundary';

export const App: React.FC = () => {
  return (
    <GlobalErrorBoundary errorComponent={<ErrorCardContainer />}>
      <SnackBarProvider>
        <RecoilRoot>
          <ReactQueryProvider>
            <Auth0Provider>
              <TrackingProvider>
                <LdProvider>
                  <AxiosProvider>
                    <BuildVersionProvider>
                      <PermissionsProvider>
                        <GlobalErrorBoundary errorComponent={<ErrorPage />} enableNavigation>
                          <AppRouter />
                        </GlobalErrorBoundary>
                      </PermissionsProvider>
                    </BuildVersionProvider>
                  </AxiosProvider>
                </LdProvider>
              </TrackingProvider>
            </Auth0Provider>
          </ReactQueryProvider>
        </RecoilRoot>
      </SnackBarProvider>
    </GlobalErrorBoundary>
  );
};
